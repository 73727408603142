export const ERROR_MESSAGES = {
  prospectType: 'COMMON.APPLICATION.ERROR_MESSAGES.PROSPECT_TYPE',
  name: 'COMMON.APPLICATION.ERROR_MESSAGES.NAME',
  cpf: 'COMMON.APPLICATION.ERROR_MESSAGES.CPF',
  cnpj: 'COMMON.APPLICATION.ERROR_MESSAGES.CNPJ',
  phone: 'COMMON.APPLICATION.ERROR_MESSAGES.PHONE',
  email: 'COMMON.APPLICATION.ERROR_MESSAGES.EMAIL',
  emailConfirmation: 'COMMON.APPLICATION.ERROR_MESSAGES.EMAIL_CONFIRMATION',
  emailCollision: 'COMMON.APPLICATION.ERROR_MESSAGES.EMAIL_COLLISION',
  accepted: 'COMMON.APPLICATION.ERROR_MESSAGES.PRIVACY_POLICY',
  generic: 'COMMON.APPLICATION.ERROR_MESSAGES.GENERIC',
};

export const FIELDS_PROPERTIES = {
  email: {
    label: 'COMMON.APPLICATION.LABEL.EMAIL',
    type: 'email',
    validations: {
      required: ERROR_MESSAGES.email,
      email: ERROR_MESSAGES.email,
    },
  },

  emailCollision: {
    label: 'COMMON.APPLICATION.LABEL.EMAIL',
    type: 'email',
    validations: {
      required: ERROR_MESSAGES.email,
      email: ERROR_MESSAGES.email,
      emailCollision: ERROR_MESSAGES.emailCollision,
    },
  },

  emailConfirmation: {
    type: 'email',
    label: 'COMMON.APPLICATION.LABEL.EMAIL_CONFIRMATION',
    validations: {
      required: ERROR_MESSAGES.email,
      email: ERROR_MESSAGES.email,
      equalsTo: {
        errorMsg: ERROR_MESSAGES.emailConfirmation,
        params: { target: 'email' },
      },
    },
  },

  emailCollisionConfirmation: {
    type: 'email',
    label: 'COMMON.APPLICATION.LABEL.EMAIL_CONFIRMATION',
    validations: {
      required: ERROR_MESSAGES.email,
      email: ERROR_MESSAGES.email,
      emailCollision: ERROR_MESSAGES.emailCollision,
      equalsTo: {
        errorMsg: ERROR_MESSAGES.emailConfirmation,
        params: { target: 'emailCollision' },
      },
    },
  },

  name: {
    type: 'text',
    label: 'COMMON.APPLICATION.LABEL.NAME',
    validations: {
      required: ERROR_MESSAGES.name,
      personName: ERROR_MESSAGES.name,
    },
  },

  cpf: {
    type: 'tel',
    label: 'COMMON.APPLICATION.LABEL.CPF',
    validations: {
      required: ERROR_MESSAGES.cpf,
      cpf: ERROR_MESSAGES.cpf,
    },
  },

  cnpj: {
    type: 'tel',
    label: 'COMMON.APPLICATION.LABEL.CNPJ',
    validations: {
      required: ERROR_MESSAGES.cnpj,
      cnpj: ERROR_MESSAGES.cnpj,
    },
  },

  phone: {
    type: 'tel',
    label: 'COMMON.APPLICATION.LABEL.PHONE',
    validations: {
      required: ERROR_MESSAGES.phone,
      phone: ERROR_MESSAGES.phone,
    },
  },
};
