import { isType } from '@nubank/nuds-web/utils/isType/isType';

import { FIELDS_PROPERTIES } from './constants';

export const getFieldProperties = fieldName => (FIELDS_PROPERTIES)[fieldName];

export const normalizeFieldValidations = (validations, formatMessage) => Object
  .entries(validations)
  .reduce((aggregate, [key, value]) => {
    if (isType('object', value)) {
      return ({
        ...aggregate,
        [key]: {
          ...value,
          errorMsg: formatMessage({ id: value.errorMsg }),
        },
      });
    }

    return ({
      ...aggregate,
      [key]: formatMessage({ id: value }),
    });
  }, {});
